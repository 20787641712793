<template>
	<div class="bigBox">
		<el-page-header @back="goBack" content=""> </el-page-header>
		<h2>油脂消耗统计</h2>
		<div class="flex-sb">
			<div class="row-me cont">
				<el-form ref="form" :model="form" label-width="80px">
					<el-date-picker class="accountPicker" v-model="form.value1" type="daterange" align="center"
						range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="YYYY-MM-DD"
						value-format="YYYY-MM-DD">
					</el-date-picker>
					<el-input v-model="form.ieme_encoded" prefix-icon="Search" placeholder="请输入IEME编码"
						class="accountPut"></el-input>
					<el-select v-model="form.company_id" placeholder="所属公司" class="electIpt" @focus="comSelect">
						<el-option v-for="(item,index) in comList" :key="index" :value="item.id" :label="item.name">
						</el-option>
					</el-select>
					<el-button type="primary" icon="Search" class="searchs" @click="search">搜索</el-button>
					<el-button class="agains" @click="resetBtn">重置
					</el-button>
				</el-form>
			</div>
			<div style="float: right;margin-top: 10px;" class="row-me row-center">
				<div class="bold">
					设备总数： <span>{{total}}</span>
				</div>
				<div class="bold">
					消耗油脂量（ml）： <span>{{allGreaseValue}}</span>
				</div>
				<div class="imgbold row-me row-center" @click="leadingOut">
					<img src="../../assets/img/icon_dc.png" class="iconImg">&nbsp;
					<span class="youzhi">导出</span>
				</div>
			</div>
		</div>
		<div class="tabsBox  margin-top20">
			<el-table :data="consList" border show-summary :summary-method="getSummaries" ref="singleTable"
				header-cell-class-name="table-header-style" cell-class-name="table-cell-style">
				<el-table-column type="selection" width="70">
				</el-table-column>
				<el-table-column type="index" label="序号" width="70" align="center">
				</el-table-column>
				<el-table-column prop="ieme_encoded" label="IEMI编码" align="center">
				</el-table-column>
				<el-table-column prop="device_encoded" label="设备编码" align="center">
				</el-table-column>
				<el-table-column prop="name" label="设备名称" align="center">
				</el-table-column>
				<el-table-column prop="company_name" label="所属公司" align="center">
				</el-table-column>
				<el-table-column prop="grease_value" label="消耗油脂量（ml）" align="center">
				</el-table-column>
			</el-table>
			<div class="row-me row-center flex-end1 margin-top10" v-if="consList.length>0">
				<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
					:current-page.sync="pageNo" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		deviceGreaseListInfo,
		sysCompanyOptionList,
		deviceGreaseListInfoImport
	} from "@/api/deviceManagement/deviceManagement.js"
	export default {
		data() {
			return {
				form: {
					value1: [],
					ieme_encoded: "",
					company_id: "",
				},
				consList: [],
				comList: [],
				allListNumber: '',
				allGreaseValue: '',
				pageNo: 1,
				total: 0,
			}
		},
		mounted() {
			this.consTabList()
		},
		methods: {
			getSummaries(param) {
				//此处打印param可以看到有两项，一项是columns，一项是data，最后一列可以通过columns.length获取到。
				const {
					columns,
					data
				} = param
				const len = columns.length
				const sums = []
				columns.forEach((column, index) => {
					//如果是第一列，则最后一行展示为“总计”两个字
					if (index === 0) {
						sums[index] = '总计'
						//如果是最后一列，索引为列数-1，则显示计算总和
					} else if (index === len - 1) {
						const values = data.map(item => Number(item[column.property]))
						if (!values.every(value => isNaN(value))) {
							sums[index] = values.reduce((prev, curr) => {
								const value = Number(curr)
								if (!isNaN(value)) {
									const number = prev + curr;
									const roundedNumber = Math.round(number * 100) / 100;
									return roundedNumber
								} else {
									const prev = prev;
									const roundedNumber1 = Math.round(number * 100) / 100;
									return roundedNumber1
									// return prev
								}
							}, 0)
						} else {
							sums[index] = 'N/A'
						}
						//如果是除了第一列和最后一列的其他列，则显示为空
					} else {
						sums[index] = ''
					}
				})
				console.log(sums, 'sumssums')
				// if (sums.length == 7) {
				// 	Math.round(sums[7])
				// }
				return sums;
			},

			goBack() {
				this.$router.go(-1);
			},
			//页数改变
			handleCurrentChange(pageNo) {
				this.pageNo = pageNo
				this.consTabList()
			},
			// 油脂消耗表格数据
			async consTabList() {
				const res = await deviceGreaseListInfo({
					start_time: this.form.value1[0],
					end_time: this.form.value1[1],
					company_id: this.form.company_id,
					ieme_encoded: this.form.ieme_encoded,
					page: this.pageNo,
				})
				this.consList = res.data.listInfo
				this.total = res.data.allListNumber
				this.allGreaseValue = res.data.allGreaseValue
				console.log(this.consList)
			},
			// 所属公司下拉框
			async comSelect() {
				const res = await sysCompanyOptionList()
				this.comList = res.data
			},
			// 搜索
			search() {
				this.consTabList()
			},
			// 重置
			resetBtn() {
				this.form.value1 = ''
				this.form.ieme_encoded = ''
				this.form.company_id = ''
				this.search()
			},
			// 导出
			async leadingOut() {
				// const res = await deviceGreaseListInfoImport({
				// 	start_time: this.form.value1[0],
				// 	end_time: this.form.value1[1],
				// 	company_id: this.form.company_id,
				// 	ieme_encoded: this.form.ieme_encoded,
				// })
				// console.log(res, '导出')
				console.log(this.form.value1, 'this.form.value1')
				if (this.form.value1.length > 0) {
					this.form.start_time = this.form.value1[0];
					this.form.end_time = this.form.value1[1];
				}
				window.open(this.$axios.defaults.baseURL + '/admin/deviceGreaseListInfoImport?' + this.$method
					.getParamsString(this.form))

				// this.form.value1.push(this.form.start_time)
				// this.form.value1.push(this.form.end_time)
				// console.log(this.form.value1, '22222')
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.bigBox {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;
	}

	.bold {
		font-weight: bold;
		padding: 0 20px;
	}

	.imgbold {
		border: 1px solid #D4D7E0;
		border-radius: 4px;
		padding: 7px 10px;
		cursor: pointer;

		.iconImg {
			width: 26px;
			height: 26px;
		}
	}

	h2 {
		margin-top: 20px;
	}

	.cont {
		margin-top: 20px;
	}

	.electIpt {
		width: 200px;
		height: 40px;
		margin-left: 10px;

		/deep/.el-input__wrapper {
			height: 38px !important;
		}
	}

	// .times {
	// 	margin-top: 5px;
	// }
	.searchs {
		margin-left: 10px;
		font-size: 16px;
	}

	/deep/.el-date-editor {
		width: 270px !important;
		height: 40px !important;
	}

	.accountPicker {
		width: 360px !important;
	}

	.accountPut {
		margin-left: 10px;
		width: 200px;
		height: 40px;
	}

	.agains {
		margin-left: 10px;
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	.fenye {
		float: right;
		margin-top: 40px;
	}

	::v-deep .el-date-editor {
		width: 240px;
	}

	::v-deep .el-button--primary {
		width: 100px;
		height: 40px;
	}
</style>